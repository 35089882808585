import axios from 'axios';
import { ApiResult } from 'interfaces/APITypes';
import { ArticleOptions, ArticleParams } from 'interfaces/ArticleTypes';
import { FeedbackAnswer } from 'interfaces/FeedbackTypes';
import {
  ActivationFormData,
  ChangeEmailFormData,
  ChangePasswordFormData,
  PasswordResetFormData,
} from 'interfaces/FormData';
import { Locale } from 'interfaces/Locale';
import { GuestPayload, LoginPayload } from 'interfaces/LoginPayload';
import { TranslationResponse } from 'interfaces/TranslationTypes';
import { UpdateAccountData } from 'interfaces/user/Account';
import { InviteUserPayload } from 'interfaces/user/InviteUser';
import { Partner } from 'interfaces/user/Partner';
import { ProfileImage, User } from 'interfaces/user/User';
import { GridRowId } from '@mui/x-data-grid';

import { buildAxiosResult } from 'utils/axios';
import { UserFeedbackFormData } from 'components/feedback/UserFeedback';
import { ServerSideFilter } from 'components/mytover/invite/UnitUserFilterSection';

export const mytoverInstance = axios.create({
  baseURL: process.env.REACT_APP_MYTOVER_API,
  withCredentials: true,
});

export class MyToverAPI {
  static async getMenu(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/menu');
    return await buildAxiosResult(response);
  }

  // User (related) actions
  static async signin(payload: LoginPayload): Promise<ApiResult> {
    await mytoverInstance.get('/sanctum/csrf-cookie');
    const response = await mytoverInstance.post('/login', payload);
    return await buildAxiosResult(response);
  }

  static async signInAsGuest(payload: GuestPayload): Promise<ApiResult> {
    await mytoverInstance.get('/sanctum/csrf-cookie');
    const response = await mytoverInstance.post('/guest_login', payload);
    return await buildAxiosResult(response);
  }

  static async checkQrAccess(token: string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/units/${token}/qr_access`);
    return await buildAxiosResult(response);
  }

  static async signout(): Promise<ApiResult> {
    const response = await mytoverInstance.post('/logout');
    return await buildAxiosResult(response);
  }

  static async getUser(id?: number | string): Promise<ApiResult> {
    const response = await mytoverInstance.get('/user' + (id ? 's/' + id : ''));
    return await buildAxiosResult(response);
  }

  static async getUserUnits(id?: number | string, values?: ServerSideFilter): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/users/${id}/units`, { params: values });
    return await buildAxiosResult(response);
  }

  static async getUserActivity(id?: number | string, values?: ServerSideFilter): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/users/${id}/activity`, { params: values });
    return await buildAxiosResult(response);
  }
  static async getUnitActivity(id?: number | string, values?: ServerSideFilter): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/units/${id}/activity`, { params: values });
    return await buildAxiosResult(response);
  }

  static async getUsers(values?: ServerSideFilter): Promise<ApiResult> {
    const response = await mytoverInstance.get('/users', { params: values });
    return await buildAxiosResult(response);
  }

  static async getUsersPerUnit(serial_number: string, values: ServerSideFilter): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/units/${serial_number}/users`, { params: values });
    return await buildAxiosResult(response);
  }

  static async getMyToverAccessBySerialNumber(serial_number: string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/units/${serial_number}/mytover`);
    return await buildAxiosResult(response);
  }

  static async getUnit(serial_number: string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/units/${serial_number}`);
    return await buildAxiosResult(response);
  }

  static async getUnits(values?: ServerSideFilter): Promise<ApiResult> {
    const response = await mytoverInstance.get('/units', { params: values });
    return await buildAxiosResult(response);
  }

  static async setUnitName(serial_number: string, name: string): Promise<ApiResult> {
    const response = await mytoverInstance.patch(`/units/${serial_number}`, { name });
    return await buildAxiosResult(response);
  }

  static async getInvitedUser(id: string, token: string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/users/${id}/${token}`);
    return await buildAxiosResult(response);
  }

  static async getRelatedUsers(values: ServerSideFilter): Promise<ApiResult> {
    const response = await mytoverInstance.get('/unit-user-relations', { params: values });
    return await buildAxiosResult(response);
  }

  static async inviteUsers(values: InviteUserPayload): Promise<ApiResult> {
    const response = await mytoverInstance.post('/users/invite', values);
    return await buildAxiosResult(response);
  }

  static async getUserInviteOptions(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/user/invite-options');
    return await buildAxiosResult(response);
  }

  static async getUnitInviteOptions(serial: string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/units/${serial}/invite-options`);
    return await buildAxiosResult(response);
  }

  static async resendInvitation(id: GridRowId): Promise<ApiResult> {
    const response = await mytoverInstance.post(`/users/${id}/resend-invitation`);
    return await buildAxiosResult(response);
  }

  static async removeUser(id: GridRowId, serial: GridRowId): Promise<ApiResult> {
    const response = await mytoverInstance.delete(`/units/${serial}/users/${id}`);
    return await buildAxiosResult(response);
  }

  static async deleteUser(user: User): Promise<ApiResult> {
    const response = await mytoverInstance.delete(`/users/${user.id}`);
    return await buildAxiosResult(response);
  }

  static async editUser(id: string, serial: string, role: string): Promise<ApiResult> {
    const response = await mytoverInstance.patch(`/units/${serial}/users/${id}`, {
      role,
    });
    return await buildAxiosResult(response);
  }

  static async removeUserUnitRelation(id: number): Promise<ApiResult> {
    const response = await mytoverInstance.delete(`/unit_user/${id}`);
    return await buildAxiosResult(response);
  }

  static async requestInvite(values: { serial_number: string; email?: string }): Promise<ApiResult> {
    const response = await mytoverInstance.post('/user/request-invite', values);
    return await buildAxiosResult(response);
  }

  static async acceptInvite(token: string): Promise<ApiResult> {
    const response = await mytoverInstance.post(`/users/accept-invite/${token}`);
    return await buildAxiosResult(response);
  }

  // Account
  static async getAccountInfo(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/account');
    return await buildAxiosResult(response);
  }

  static async updateAccount(account: UpdateAccountData): Promise<ApiResult<User>> {
    const response = await mytoverInstance.patch('/account', account);
    return await buildAxiosResult(response);
  }

  static async deleteAccount(): Promise<ApiResult> {
    const response = await mytoverInstance.delete('/account');
    return await buildAxiosResult(response);
  }

  static async recoverAccount(token: string): Promise<ApiResult> {
    const response = await mytoverInstance.post(`/account/recover/${token}`);
    return await buildAxiosResult(response);
  }

  static async updateEmail(values: ChangeEmailFormData): Promise<ApiResult> {
    const response = await mytoverInstance.patch('/account/email', values);
    return await buildAxiosResult(response);
  }

  static async recoverEmail(token: string): Promise<ApiResult> {
    const response = await mytoverInstance.post(`/account/email/recover/${token}`);
    return await buildAxiosResult(response);
  }

  static async verifyEmail(token: string): Promise<ApiResult> {
    const response = await mytoverInstance.post(`/account/email/verify/${token}`);
    return await buildAxiosResult(response);
  }

  static async updatePassword(values: ChangePasswordFormData): Promise<ApiResult> {
    const response = await mytoverInstance.post('/account/change-password', values);
    return await buildAxiosResult(response);
  }

  static async getProfileImages(): Promise<ApiResult<{ data: ProfileImage[] }>> {
    const response = await mytoverInstance.get('/account/profile-images');
    return await buildAxiosResult(response);
  }

  // Partners
  static async getPartners(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/partners');
    return await buildAxiosResult(response);
  }

  static async getPartner(id: number | string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/partners/${id}`);
    return await buildAxiosResult(response);
  }

  static async updatePartner({ id, update }: { id: number; update: any | null }): Promise<ApiResult> {
    const response = await mytoverInstance.patch(`/partners/${id}`, update);
    return await buildAxiosResult(response);
  }

  static async getPartnerUsers(partner: string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/partners/${partner}/users`);
    return await buildAxiosResult(response);
  }

  static async verifyPartnerEmailDomain(partner: Partner): Promise<ApiResult> {
    const response = await mytoverInstance.post(`/partners/${partner.id}/verify-domain`);
    return await buildAxiosResult(response);
  }

  // Activation
  static async setPasswordActivation(values: ActivationFormData): Promise<ApiResult> {
    const response = await mytoverInstance.post('/user/activate', values);
    return await buildAxiosResult(response);
  }

  // Password
  static async requestPasswordReset(email: string): Promise<ApiResult> {
    const response = await mytoverInstance.post('/user/reset-password', {
      email,
    });
    return await buildAxiosResult(response);
  }

  static async sendPasswordReset(payload: PasswordResetFormData): Promise<ApiResult> {
    const response = await mytoverInstance.post(`/user/reset-password/${payload.token}`, payload);
    return await buildAxiosResult(response);
  }

  // Content
  static async getLocales(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/locales');
    return await buildAxiosResult(response);
  }

  static async setLocale(locale: Locale): Promise<ApiResult> {
    const response = await mytoverInstance.post('/account/locale', {
      code: locale.code,
    });
    return await buildAxiosResult(response);
  }

  static async getArticles(options?: ArticleOptions): Promise<ApiResult> {
    const response = await mytoverInstance.get('/articles', {
      params: options,
    });
    return await buildAxiosResult(response);
  }

  static async getFeaturedArticles(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/articles/featured');
    return await buildAxiosResult(response);
  }

  static async getArticle(
    id: string,
    locale: string | null = null,
    withTranslations: boolean = false,
  ): Promise<ApiResult> {
    const params: ArticleParams = {};
    if (locale) {
      params.locale = locale;
    }
    if (withTranslations) {
      params.with_translations = withTranslations;
    }
    const response = await mytoverInstance.get(`/articles/${id}`, {
      params,
    });
    return await buildAxiosResult(response);
  }

  static async updateArticle(id: string, data: any): Promise<ApiResult> {
    const response = await mytoverInstance.patch(`/articles/${id}`, { ...data });
    return await buildAxiosResult(response);
  }

  static async refreshArticle(id: number, now = false): Promise<ApiResult> {
    const response = await mytoverInstance.post(`/articles/${id}/refresh`, { now });
    return await buildAxiosResult(response);
  }

  static async refreshArticles(now = false): Promise<ApiResult> {
    const response = await mytoverInstance.post('/articles/refresh', { now });
    return await buildAxiosResult(response);
  }

  static async getAttachment(id: string, filename: string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/articles/${id}/${filename}`);
    return await buildAxiosResult(response);
  }

  static async getCategories(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/categories');
    return await buildAxiosResult(response);
  }

  static async getCategory(id: string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/categories/${id}`);
    return await buildAxiosResult(response);
  }

  // Support
  static async getSoftwareUpdate(version: string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/downloads/software_updates/${version}?link=true`);
    return await buildAxiosResult(response);
  }

  // Feedback
  static async getFeedback(options?: any): Promise<ApiResult> {
    const response = await mytoverInstance.get('/feedback', {
      params: options,
    });
    return await buildAxiosResult(response);
  }

  static async postFeedback(payload: UserFeedbackFormData | FeedbackAnswer): Promise<ApiResult> {
    const response = await mytoverInstance.post('/feedback', payload);
    return await buildAxiosResult(response);
  }

  static async getFeedbackQuestions(event?: string): Promise<ApiResult> {
    const response = await mytoverInstance.get(`/feedback/questions/${event}`);
    return await buildAxiosResult(response);
  }

  // Reports
  static async getReports(options?: any): Promise<ApiResult> {
    const response = await mytoverInstance.get('/reports', {
      params: options,
    });
    return await buildAxiosResult(response);
  }

  // Policy
  static async getPrivacyPolicy(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/privacy-policy');
    return await buildAxiosResult(response);
  }

  static async acceptPrivacyPolicy(hash: string): Promise<ApiResult> {
    const response = await mytoverInstance.post(`/privacy-policy/${hash}/accept`);
    return await buildAxiosResult(response);
  }

  // End User License Agreement (EULA)
  static async getEULA(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/eula');
    return await buildAxiosResult(response);
  }

  static async acceptEULA(hash: string): Promise<ApiResult> {
    const response = await mytoverInstance.post(`/eula/${hash}/accept`);
    return await buildAxiosResult(response);
  }

  // Processor Terms
  static async getProcessorTerms(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/processor-terms');
    return await buildAxiosResult(response);
  }

  // KPI
  static async getKpis(): Promise<ApiResult> {
    const response = await mytoverInstance.get('/kpi');
    return await buildAxiosResult(response);
  }

  // Translations
  static async updateTranslations(payload: FormData): Promise<ApiResult<TranslationResponse>> {
    const response = await mytoverInstance.post('/tover/translations', payload);
    return await buildAxiosResult(response);
  }
}
